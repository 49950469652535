import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as CloseButton } from '../../assets/close.svg';
import ModalImg from "../../assets/ai-desktop.jpg"
import ModalMobileImg from "../../assets/ai-mobile.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

function OfferModal() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    return (
        <div className='modal-container'>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <div className='modal-data'>
                    <div className='close-button'>
                        <CloseButton onClick={handleClose} />
                    </div>
                    <Modal.Body className='modal-text'>
                        <div className='modal-mobile-image'>
                            <img src={ModalMobileImg} alt="Modal" className='img' />
                        </div>
                        <div dir="rtl" className='text'>
                            <h2 className='model-title'>🎉 عرض العام الجديد !🎉</h2>
                            <p className='discount-paragraph'>🚀 احصل على خصم 10٪ على جميع خدماتنا عند حجز استشارة مجانية <span> قبـــل </span>  فبراير 2025 !</p>
                            <p className='zane-paragraph'>دعـــ Zane Technology تساعدك في تحويل أعمالك من خلال حلول مبتكرة مثل تطبيقات الويب، وتطبيقات الجوال، والحوسبة السحابية والمزيد.</p>
                            <a
                                href="#booking"
                                className='modal-button button'
                                type='button'
                                onClick={() => {
                                    setShow(false)
                                }}>
                                احـــــجز الآن
                            </a>
                            <p className='date-paragraph'> 📅 لا تفوّت الفرصة—احجز استشارتك المجانية الآن !</p>
                        </div>
                        <div className='modal-image'>
                            <img src={ModalImg} alt="Modal" className='img' />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}

export default OfferModal;