import { Footer } from './components/Footer/Footer'
import { Header } from './components/Header/Header'
import { Main } from './components/Main/Main'
// import EngOfferModal from './components/Offers/EngModal'
import OfferModal from './components/Offers/Modal'
import { StatisticsProvider } from './context/statisticsContext'
import { ThemeProvider } from './context/themeContext'
import { GlobalStyle } from './styles/global'

import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <StatisticsProvider>
      <ThemeProvider>
        <OfferModal />
        {/* <EngOfferModal /> */}
        <GlobalStyle></GlobalStyle>
        <Header></Header>
        <Main></Main>
        <Footer></Footer>
      </ThemeProvider>
    </StatisticsProvider>
  )
}

export default App
